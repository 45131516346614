import React, {useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import './login.css';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { playGame, loginUser, selectTempVideoId } from '../store/screenSlice';


library.add(faPhone);


const Login = () => {
    const dispatch = useDispatch();
    const [linkHref, setLinkHref] = useState('');
    const id = useSelector(selectTempVideoId);
    const [msisdnValue, setMsisdnValue] = useState('');

    const resetMsisdn = () => {
        setMsisdnValue('');
    }

    const handleChangeMsisdn = (event) => {
        setMsisdnValue(event.target.value);
    };
   
    const handleLogin = () => {
        const error_message = document.getElementById('error_message') as HTMLElement;
        const msisdnElement = document.getElementById('msisdn') as HTMLInputElement;
        const msisdn = msisdnElement ? msisdnElement.value : '';
        const validNumbers = /^[0-9]+$/;
        
        if(!msisdn) {
            msisdnElement.focus();
            error_message.textContent='Ingrese su n\u00FAmero de tel\u00E9fono.';
            return false;
        }
        
        if(msisdn!=='123') {
            if(msisdn.length<6 || !msisdn.match(validNumbers)) {
                msisdnElement.focus();
                error_message.textContent='El tel\u00E9fono no parece v\u00E1lido';
                return false;
            }
        }


        const data = {
            msisdn: msisdn,
            client: ''
        }

        error_message.textContent='\u00A0';

        axios.post(`${process.env.REACT_APP_API_URL}?call=login`, data)
        .then(response => {
            if(response.data.result===1) { //success
                dispatch(loginUser());
                dispatch(playGame(id));
            } else { //no valido
                if (error_message) {
                    error_message.textContent = 'El tel\u00E9fono no est\u00E1 suscripto';
                }
                if(response.data.redirect) {
                    setLinkHref(response.data.redirect);
                }
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    return (
        <>
            <div id="containerLogin">
                <div className="d-flex justify-content-center h-100" id="innerContainer">
                    <div className="card">
                        <div className="card-header" id="header">
                            <h3>BeautySwiper</h3>
                        </div>
                        <div className="card-body" id="formDiv">
                            <form id="form">
                                <div className="input-group form-group" id="fields">
                                    <div className="input-group-prepend">
                                    <span className="input-group-text" id="phoneIcon"><FontAwesomeIcon icon="phone"/></span>
                                    </div>
                                    <input type="text" className="form-control" id="msisdn" value={msisdnValue} placeholder="Tel&eacute;fono" onClick={resetMsisdn} onChange={handleChangeMsisdn} />
                                    
                                </div>
                                <div className="form-group" id="logindiv">
                                    <input type="button" value="Login" id="login" className="btn login_btn" onClick={handleLogin} />
                                </div>
                            </form>
                        </div>
                        <div id="container_error">
                            <div id="error_message">&nbsp;</div>
                            <div id="success_message">&nbsp;</div>
                        </div>
                        <div className="card-footer" id="divLink" style={{ display: linkHref ? 'block' : 'none' }}>
                            <div className="d-flex justify-content-center links">
                                No tienes una cuenta aun?<a href={linkHref} id="landing">Suscr&iacute;bete</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login